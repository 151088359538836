import React, { useState, useEffect, useRef } from "react";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import ScrollToTop from "react-scroll-to-top";
import { FiInstagram } from "react-icons/fi";
import { CiLocationOn } from "react-icons/ci";
import { CiTwitter } from "react-icons/ci";
import { FaFacebookF, FaDribbble,FaTwitter } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import {Nav,Navbar} from "react-bootstrap";
import { FaRegUser,FaRegMap } from "react-icons/fa";
import { Offcanvas } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import logo from "../../assets/images/logo.png";
import { IoStar, IoStarHalfOutline } from "react-icons/io5";
const Header = () => {
  // const location = useLocation();
  const [activeSection, setActiveSection] = useState("home");
  const sections = useRef([]);

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };

  const handleMenuClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    sections.current = document.querySelectorAll("[data-section]");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const activeStyle = {
    color: "#ac216c",
    borderBottom: "2px solid #ac216c",
    padding: "14px",
  };
  // header off canvas//
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  return (
    <>
    
      {/* desktop header */}
      <header className="desktop-hesder">
        <Navbar collapseOnSelect expand="lg" fixed="top" className="sticky-top">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} className="header-logo" alt="..." />
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <ul className="desktop-menu">
            
                  <li
                    className={
                      activeSection === "about" ? "active" : "inactive"
                    }
                  >
                    {/* <a
                      style={activeSection === "about" ? activeStyle : {}}
                      onClick={() => handleMenuClick("about")}
                    >
                      Who We Are
                    </a> */}
                    <NavLink to="/" state={{"id":"about"}}>
                      <span>Who We Are</span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      activeSection === "sevices" ? "active" : "inactive"
                    }
                  >
                    {/* <a
                      style={activeSection === "sevices" ? activeStyle : {}}
                      onClick={() => handleMenuClick("sevices")}
                    >
                      Services
                    </a> */}
                    <NavLink to="/" state={{"id":"sevices"}}>
                      <span>Services</span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      activeSection === "feature" ? "active" : "inactive"
                    }
                  >
                    {/* <a
                      style={activeSection === "feature" ? activeStyle : {}}
                      onClick={() => handleMenuClick("feature")}
                    >
                      Feature
                    </a> */}
                    <NavLink to="/" state={{"id":"feature"}}>
                      <span>Feature</span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      activeSection === "membership" ? "active" : "inactive"
                    }
                  >
                    {/* <a
                      style={activeSection === "membership" ? activeStyle : {}}
                      onClick={() => handleMenuClick("membership")}
                    >
                      Membership
                    </a> */}
                     <NavLink to="/" state={{"id":"membership"}}>
                      <span>Membership</span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      activeSection === "download" ? "active" : "inactive"
                    }
                  >
                    {/* <a
                      style={activeSection === "download" ? activeStyle : {}}
                      onClick={() => handleMenuClick("download")}
                    >
                      Download
                    </a> */}
                    <NavLink to="/" state={{"id":"download"}}>
                      <span>Download</span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      activeSection === "Contact" ? "active" : "inactive"
                    }
                  >
                    <NavLink to="/Contact"
                      // style={activeSection === "Contact" ? activeStyle : {}}
                      // onClick={() => handleMenuClick("Contact")}
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </Nav>
              <Nav>
                <ul className="desktop-menu">
                  <li>
                    <NavLink to="/Login">
                      <FaRegUser className="header-call" />
                      <span>Login</span>
                    </NavLink>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      {/* mobile view responsive header */}
      <header className="mobile-hesder">
        <Navbar collapseOnSelect expand="lg" fixed="top" className="sticky-top">
          <Container>
            <Navbar.Brand href="#">
              <img src={logo} className="header-logo" alt="..." />
            </Navbar.Brand>

            <Nav>
              <ul className="menu-responivee">
                <li>
                  <NavLink to="/#">
                    <div>
                      <FaRegUser className="header-call" />
                    </div>
                  </NavLink>
                </li>
                <li>
                  <div className="menu">
                    <FiMenu onClick={handleShow} />
                  </div>
                </li>
              </ul>
            </Nav>
          </Container>
        </Navbar>
      </header>
      {/* <Offcanvas
        show={showOffcanvas}
        onHide={handleClose}
        placement="start"
        className={{ width: "35%" }}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="desktop-menu mobile-responsive">
          
            <li className={activeSection === "about" ? "active" : "inactive"}>
              <a
                style={activeSection === "about" ? activeStyle : {}}
                onClick={() => handleMenuClick("about")}
              >
                About Us
              </a>
            </li>
            <li className={activeSection === "sevices" ? "active" : "inactive"}>
              <a
                style={activeSection === "sevices" ? activeStyle : {}}
                onClick={() => handleMenuClick("sevices")}
              >
                Services
              </a>
            </li>
            <li className={activeSection === "feature" ? "active" : "inactive"}>
              <a
                style={activeSection === "feature" ? activeStyle : {}}
                onClick={() => handleMenuClick("feature")}
              >
                Feature
              </a>
            </li>
            <li
              className={activeSection === "membership" ? "active" : "inactive"}
            >
              <a
                style={activeSection === "membership" ? activeStyle : {}}
                onClick={() => handleMenuClick("membership")}
              >
                Membership
              </a>
            </li>
            <li
              className={activeSection === "download" ? "active" : "inactive"}
            >
              <a
                style={activeSection === "download" ? activeStyle : {}}
                onClick={() => handleMenuClick("download")}
              >
                Download
              </a>
            </li>
            <li className={activeSection === "contact" ? "active" : "inactive"}>
              <a
                style={activeSection === "contact" ? activeStyle : {}}
                onClick={() => handleMenuClick("contact")}
              >
                Contact
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas> */}

    </>
  );
};

export default Header;

import React, { useState, useEffect, useRef } from "react";
import Home from "../Components/Home/Home";
const HomePage = () => {

  return (
    <>
   <Home/>
    </>
  );
};

export default HomePage;

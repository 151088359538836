import React, { useState } from "react";
import Termcondition from "../Components/Privacys/Termcondition";
const Term_Condition = () => {
  return (
    <>
    <Termcondition/>
    </>
  );
};

export default Term_Condition;

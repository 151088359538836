import React, { useState } from "react";
import Faqs from "../Components/Privacys/Faqs";
const Faq = () => {
  return (
    <>
    <Faqs/>
    </>
  );
};

export default Faq;

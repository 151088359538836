import React, { useState } from "react";
import Testimonials from "../Components/Customer/Testimonials";
const Testimonial = () => {
  return (
    <>
    <Testimonials/>
    </>
  );
};

export default Testimonial;

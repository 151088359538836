
import { useEffect, useState } from 'react'
import  Routes  from "./Routes"
import "./App.css";
import GoToTop from "../src/GoToTop";
const App = () => {

  return (
    <>
      <Routes/>
      <GoToTop /> 
    </>
  );
};

export default App;
import React, { useState } from "react";
import Privacy from "../Components/Privacys/Privacy";
const Privacy_Policy = () => {
  return (
    <>
    <Privacy/>
    </>
  );
};

export default Privacy_Policy;

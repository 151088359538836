import React, { useState } from "react";
// import { Accordion, Card, Button } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

import { NavLink } from "react-router-dom";
import Footer from "../Footers/Footer";
import Header from "../Navbar/Header";
import "./privacy.css";
const Faqs = () => {
  return (
    <>
      <Header />

      <div className="faq">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-plocy">
                <h2>Frequently Asked Questions</h2>
                <p>
                  If you’re trying to get to know us better, you’ve come to the
                  right place. This is Tinder at a glance.
                </p>
                <div className="btn_android mt-3">
                  <a className="custom-button" href="/Registration">
                    Join Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-head">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fq-set">
                <h5>All About How MeetupsFellow Works</h5>
                <h6>Is MeetupsFellow free?</h6>
                <p>
                  MeetupsFellow can be downloaded for free in the App Store and Google
                  Play Store or visit https://tinder.com to use Tinder for Web.
                  Basic features let you create a profile, use the Swipe Right®
                  feature to Like someone and use the Swipe Left™ feature to
                  pass.
                </p>
                <h6>Are MeetupsFellow profiles real?</h6>
                <p>
                  MeetupsFellow offers Photo Verification to make sure the person
                  you’re talking to matches their photos. Verified profiles will
                  have a blue checkmark and we strongly suggest you get one—they
                  look good on everyone and help keep our community safe.
                </p>
                <h5>All About MeetupsFellow Features and Subscriptions</h5>
              </div>
            </div>

              <div className="all-faq">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="faq-title">
                      How does MeetupsFellow Gold™ work?
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        See all your admirers in one place when you subscribe to
                        Tinder Gold™. Save time by auto-matching, dismissing,
                        and expanding profiles directly from the Likes You grid.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    <div className="faq-title">
                      How does MeetupsFellow Boost work?
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      A Boost lets you be one of the top profiles in your area
                      for 30 minutes. Look out for the purple lightning bolt
                      whenever you want up to 10x more eyes on you.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    <div className="faq-title">
                      How does MeetupsFellow Top Picks work?
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      We’re not a fan of labels, but we recognize a good thing
                      when we see it. With Top Picks, members can choose 1 out
                      of 10 categorized profiles a day while paid subscribers
                      will get a chance to view a curated list of 10 people.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    <div className="faq-title">
                      What does Tinder Gold™ offer?
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Save time by seeing who Likes You with Tinder Gold™ and
                      enjoy an ad-free experience with Unlimited Likes, Rewind®,
                      Passport™, 5 daily Super Likes™, and 1 monthly Boost.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    <div className="faq-title">
                      Where can you see Super Likes™ on Tinder?
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Look for the blue star on a potential match’s profile to
                      see who sent you a Super Like™. If you want faster
                      results, upgrade to Tinder Gold™ and see everyone who
                      Likes You.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    <div className="faq-title">
                      Where can you see Super Likes™ on Tinder?
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Look for the blue star on a potential match’s profile to
                      see who sent you a Super Like™. If you want faster
                      results, upgrade to Tinder Gold™ and see everyone who
                      Likes You.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faqs;

import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Testimonials.css"; // Assuming you place your CSS in this file
import Footer from "../Footers/Footer";
import Header from "../Navbar/Header";
const testimonials = [
  {
    text: "Why is this important? Because clients want to know the businesses they depend on for advice, are well managed in their own right. Not only that but this event gives you the chance to give your back-office team",
    name: "Mahfuz Riad",
    designation: "Ui Designer & CEO",
    imgSrc: "http://t.commonsupport.com/adro/images/resource/thumb-1.jpg",
  },
  {
    text: "Why is this important? Because clients want to know the businesses they depend on for advice, are well managed in their own right. Not only that but this event gives you the chance to give your back-office team",
    name: "Mahfuz Riad",
    designation: "Ui Designer & CEO",
    imgSrc: "http://t.commonsupport.com/adro/images/resource/thumb-1.jpg",
  },
  {
    text: "Why is this important? Because clients want to know the businesses they depend on for advice, are well managed in their own right. Not only that but this event gives you the chance to give your back-office team",
    name: "Mahfuz Riad",
    designation: "Ui Designer & CEO",
    imgSrc: "http://t.commonsupport.com/adro/images/resource/thumb-1.jpg",
  },
];

const Testimonials = () => {
  return (
    <>
      <Header />

      <section className="testimonial-section">
        <div className="large-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sec-title">
                  <span className="title">Customer Reviews</span>
                  <h2>What Our client say ?</h2>
                </div>
              </div>
            </div>
          </div>

          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            //   scrollbar={{ draggable: true }}
            //   onSwiper={(swiper) => console.log(swiper)}
            //   onSlideChange={() => console.log('slide change')}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="testimonial-block">
                  <div className="inner-box">
                    <p>{testimonial.text}</p>
                    <div className="info-box">
                      <div className="thumb">
                        <img src={testimonial.imgSrc} alt={testimonial.name} />
                      </div>
                      <h4 className="name">{testimonial.name}</h4>
                      <p>{testimonial.designation}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="thumb-layer paroller">
            <figure className="image">
              <img
                src="http://t.commonsupport.com/adro/images/resource/user-thumbs.png"
                alt="User Thumbs"
              />
            </figure>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Testimonials;

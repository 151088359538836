import React, { useState, useEffect, useRef } from "react";
import MemberDetails from "../Components/Home/MemberDetails";
const MemberDetail = () => {

  return (
    <>
   <MemberDetails/>
    </>
  );
};

export default MemberDetail;

import React, { useState } from "react";
import Contact from "../Components/Contacts/Contact";
const Contact1 = () => {
  return (
    <>
    <Contact/>
    </>
  );
};

export default Contact1;
